import React from "react";
import Renderer from "./Renderer";
import Home from './components/home/home';
import { Routes, Route } from "react-router-dom";
import './App.scss';

import 'bootstrap/dist/js/bootstrap.bundle.min';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';

Amplify.configure(amplifyconfig);

library.add(fab)

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:code" element={<Renderer />} />
      </Routes>
    </div>
  );
}

export default App;
