import React, { Suspense } from "react";

const selector = (block) => {
  if (block.template) {
    const LazyComponent = React.lazy(() =>
      import(`./components/${block.template}/component`)
    );
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <LazyComponent {...block} />
      </Suspense>
    );
  }
  return React.createElement(
    () => <div>The component {block.template} has not been created yet.</div>,
    { key: block.id }
  );
};

export default selector;
